<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Camera'"
        >
          <!-- <InputWrapper
            type="info"
            :label="'Configure the base camera settings'"
          /> -->

          <InputWrapper
            type="number"
            :label="'Camera FOV'"
            :value="getSetting('camera.fov')"
            :units="'°'"
            :min="1"
            :max="179"
            @change="onChange('camera.fov', $event)"
          />
          <InputWrapper
            type="number"
            :label="'Camera Near'"
            :value="getSetting('camera.near')"
            @change="onChange('camera.near', $event)"
          />
          <InputWrapper
            type="number"
            :label="'Camera Far'"
            :value="getSetting('camera.far')"
            @change="onChange('camera.far', $event)"
          />
        </BlockGroup>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../GuiSettingMixin'

export default {
  name: 'WorldCameraBlock',

  mixins: [mixin]

}
</script>
